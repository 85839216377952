import { productPaymentTypes, productTypes } from '@/core'
import { billingCycles } from '@/core/ryanada'
import { AddonDTO } from '@/dtos'
export abstract class ProductDTO {
  public cartIndex?: string
  public id: string
  public productType: productTypes
  public paymentType: productPaymentTypes
  public requiresDomain: boolean
  public billingCycle: billingCycles
  public addons: AddonDTO[]
  public hasCustomFields: boolean

  constructor(
    id: string,
    productType: productTypes,
    paymentType: productPaymentTypes,
    requiresDomain: boolean,
    billingCycle: billingCycles,
    addons: AddonDTO[],
    hasCustomFields: boolean
  ) {
    this.id = id
    this.productType = productType
    this.paymentType = paymentType
    this.requiresDomain = requiresDomain
    this.billingCycle = billingCycle
    this.addons = addons
    this.hasCustomFields = hasCustomFields
  }
}
