import '../sass/theme.scss'
import { setupBuildTagging } from './setup/build-tagging'

import { setupHeader } from './setup/header'
import { setupNavigation } from './setup/navigation'
import { setupProductTable } from './setup/products'
setupNavigation()
setupProductTable()
setupHeader()

const rootEl = document.querySelector<HTMLElement>('#footer')
if (!rootEl) {
  throw new Error('Build Tagging: Root Element Not Set')
}

setupBuildTagging(rootEl, 'wordpress')
