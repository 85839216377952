import { billingCycles } from '@/core/ryanada'
import { AddonDTO } from '@/dtos'
import { ProductDTO } from '@/abstract'

export class CustomField {
  public id: string
  public value: string

  constructor(id: string, value: string) {
    this.id = id
    this.value = value
  }
}

export abstract class ProductModel<DTO extends ProductDTO> {
  protected dto: DTO
  protected token: string
  public domain?: string
  protected customFields: CustomField[] = []

  constructor(dto: DTO, token: string) {
    this.dto = dto
    this.token = token
  }

  public get id(): string {
    return this.dto.id
  }

  public get cartIndex(): string | undefined {
    return this.dto.cartIndex
  }

  public get isInCart(): boolean {
    return this.cartIndex !== undefined
  }

  public get requiresDomain(): boolean {
    return this.dto.requiresDomain
  }

  public get hasCustomFields(): boolean {
    return this.dto.hasCustomFields
  }

  public setCartIndex(cartIndex: string): void {
    this.dto.cartIndex = cartIndex
  }

  public setBillingCycle(billingCycle: billingCycles): void {
    this.dto.billingCycle = billingCycle
  }

  public addCustomField(id: string, value: string): void {
    this.customFields.push(new CustomField(id, value))
  }

  public removeCustomField(id: string): void {
    this.customFields = this.customFields.filter((field) => field.id !== id)
  }

  public addAddon(addon: AddonDTO): void {
    this.dto.addons.push(addon)
  }

  public removeAddon(id: string): void {
    this.dto.addons = this.dto.addons.filter((addon) => addon.id !== id)
  }
}
