import { ServerModel, HostingModel } from '@/models'
import { ModalView } from '@/abstract'
import { CustomFieldView } from '@/views'

export class CustomFieldModalView extends ModalView<
  ServerModel | HostingModel
> {
  private customFieldView: CustomFieldView
  constructor(modal: HTMLElement, customFieldView: CustomFieldView) {
    super(modal)
    this.customFieldView = customFieldView
  }

  public async onSubmit(
    model: ServerModel | HostingModel
  ): Promise<ServerModel | HostingModel> {
    this.customFieldView.setCurrentProductEl(model.id)
    const newModel = await this.customFieldView.onSubmit(model)
    this.close()
    return newModel
  }
}
