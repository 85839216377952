import anime, { AnimeTimelineInstance } from 'animejs'

export const openProductPlans = (
  widgetToOpen: HTMLElement,
  widgetsToClose: NodeListOf<HTMLElement>
): void => {
  // skipcq
  const tl: AnimeTimelineInstance = anime.timeline({
    easing: 'cubicBezier(.17,.67,.83,.67)',
    duration: 200
  })

  // skipcq
  tl.add({
    complete: () => {
      widgetsToClose.forEach((widget) => {
        widget.style.display = 'none'
        widget.classList.remove('active')
      })
    },
    targets: widgetsToClose,
    opacity: 0,
    translateX: '100%'
  }).add({
    begin: () => {
      widgetToOpen.style.display = 'flex'
      widgetToOpen.classList.add('active')
    },
    targets: widgetToOpen,
    opacity: 1,
    translateX: 0
  })
}
