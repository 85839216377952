import { ProductModel } from '@/abstract'
import { ModelMethods } from '@/core/ryanada'
import { productTypes } from '@/core'
import { AddonDTO, HostingDTO } from '@/dtos'
import { HostingAcceptedRequestData, HostingRequestData } from '@/http'

export class HostingModel
  extends ProductModel<HostingDTO>
  implements
    ModelMethods<HostingRequestData, HostingAcceptedRequestData, HostingModel>
{
  public clone(): HostingModel {
    const dtoCopy = JSON.parse(JSON.stringify(this.dto)) as HostingDTO
    const modelCopy = new HostingModel(dtoCopy, this.token)
    modelCopy.domain = this.domain
    modelCopy.customFields = this.customFields
    return modelCopy
  }

  public getRequestData(): HostingRequestData {
    return new HostingRequestData(
      this.dto.billingCycle,
      this.dto.addons,
      this.customFields,
      this.token
    )
  }

  public getAcceptedRequestData(i: string): HostingAcceptedRequestData {
    return new HostingAcceptedRequestData(
      i,
      this.dto.billingCycle,
      this.dto.addons,
      this.customFields,
      this.token
    )
  }

  public get isServer(): boolean {
    return this.dto.productType === productTypes.SERVER
  }

  public get requiresDomain(): boolean {
    return this.dto.requiresDomain
  }

  public addAddon(addon: AddonDTO): void {
    this.dto.addons.push(addon)
  }

  public removeAddon(id: string): void {
    this.dto.addons = this.dto.addons.filter((addon) => addon.id !== id)
  }
}
