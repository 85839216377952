import isFQDN from 'validator/lib/isFQDN'

export class RegXUtils {
  extractPriceString(str: string): string {
    const regX = /[+-]?\d+(\.\d+)?/u
    const match = regX.exec(str)
    const price = match?.shift()
    if (price) {
      return price
    }
    throw new Error('regeX: extract price from url failed')
  }

  extractCartIndexParam(url: string): string | undefined {
    const regX = /i=\d+/u
    const match = regX.exec(url)
    const index = match?.shift()
    return index
  }

  extractCartIndex(str: string): string {
    const regX = /\d+/u
    const match = regX.exec(str)
    const index = match?.shift()
    if (index) {
      return index
    }
    throw new Error('regeX: failed to extract cart index from cart-index param')
  }

  hasConfigureProduct(url: string): boolean {
    const regX = /a=confproduct/u
    return regX.test(url)
  }

  isDomain(str: string): boolean {
    return isFQDN(str)
  }

  isNotEmpty(str: string): boolean {
    const regeX = /\S/u
    return regeX.test(str)
  }
}

export const regXUtils = new RegXUtils()
