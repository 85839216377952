export enum productTypes {
  HOSTING = 'hosting',
  RESELLER_HOSTING = 'reseller-hosting',
  SERVER = 'server',
  OTHER = 'other'
}
export enum productPaymentTypes {
  RECURRING = 'recurring',
  ONE_TIME = 'onetime',
  FREE = 'free'
}

export enum Modals {
  DOMAIN,
  CUSTOM_FIELDS,
  SERVER,
  PRICING
}
