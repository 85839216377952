/**
 * @param {HTMLAnchorElement} groupNav
 */
export const setupMobileGroupNav = (groupNav: HTMLAnchorElement): void => {
  const toggleDropdownButton = groupNav.querySelector<HTMLAnchorElement>(
    '.js-nav__group-name'
  )
  const dropdown = groupNav.querySelector<HTMLElement>('.js-nav__dropdown')

  toggleDropdownButton?.addEventListener('click', (event) => {
    event.preventDefault()
    dropdown?.classList.toggle('active')
  })
}
