export class UIUtils {
  activateWidget(element: HTMLElement): void {
    element.classList.add('is-active')
  }

  deactivateWidget(element: HTMLElement): void {
    element.classList.remove('is-active')
  }

  toggleWidget(element: HTMLElement): void {
    element.classList.toggle('is-active')
  }
}

export const uiUtils = new UIUtils()
