export abstract class CommonHTTP {
  handleReloadPage(response: Response): void {
    if (response.status === 200) {
      location.reload()
    }
  }

  gotToCart(): void {
    location.replace('cart.php?a=view')
  }
}
