import { AuthResponseBody } from '../../core/theme'
import { API_ENDPOINT, WHMCS_ROOT } from '../../utils/variables'

const HTTP_AUTH = `${API_ENDPOINT}/user`

const getAuthDetails = async (): Promise<AuthResponseBody> => {
  const responseRAW = await fetch(HTTP_AUTH, {
    method: 'GET',
    mode: 'cors',
    credentials: 'include'
  })

  const response: AuthResponseBody = await responseRAW.json()
  return response
}

export const setupAuth = async (
  loginLogoutButtons: NodeListOf<HTMLAnchorElement>,
  registerClientAreaButtons: NodeListOf<HTMLAnchorElement>
): Promise<void> => {
  const authDetails = await getAuthDetails()

  const editButton = (
    button: HTMLAnchorElement,
    url: string,
    cssReplace: [oldToken: string, newToken: string],
    btnText: string
  ): void => {
    const iconEL = button.querySelector('.icon')
    const textEL = button.querySelector('.text')

    if (!iconEL) {
      throw new Error('Auth: icon holder is missing')
    }

    if (!textEL) {
      throw new Error('Auth: text holder is missing')
    }

    button.href = `${WHMCS_ROOT}/${url}`
    iconEL.classList.replace(cssReplace[0], cssReplace[1])
    textEL.textContent = btnText
  }

  if (!authDetails.error) {
    loginLogoutButtons.forEach((button): void => {
      editButton(
        button,
        'logout.php',
        ['fa-user', 'fa-chevron-circle-left'],
        'logout'
      )
    })

    registerClientAreaButtons.forEach((button) => {
      editButton(
        button,
        'clientarea.php',
        ['fa-user-plus', 'fa-info-circle'],
        'Client Area'
      )
    })
    return
  }

  loginLogoutButtons.forEach((button): void => {
    editButton(
      button,
      'index.php?rp=/login',
      ['fa-chevron-circle-left', 'fa-user'],
      'Login'
    )
  })

  registerClientAreaButtons.forEach((button) => {
    editButton(
      button,
      'register.php',
      ['fa-info-circle', 'fa-user-plus'],
      'Register'
    )
  })
}
