import { billingCycles } from '@/core/ryanada'
import { AddonDTO } from '@/dtos'
import {
  AcceptedProductRequestData,
  ProductRequestData,
  CustomField
} from '@/abstract'

export class HostingRequestData extends ProductRequestData {
  constructor(
    billingCycle: billingCycles | undefined,
    addons: AddonDTO[],
    customFields: CustomField[],
    token: string
  ) {
    super(token)
    this.billingCycle = billingCycle
    this.addons = addons
    this.customFields = customFields
  }

  get formData(): FormData {
    const formData = new FormData()

    formData.append('ajax', this.ajax.toString())
    formData.append('a', this.a)
    formData.append('token', this.token)
    formData.append('configure', this.configure.toString())

    if (this.billingCycle) {
      formData.append('billingcycle', this.billingCycle)
    }

    this.addons.forEach((addon) => {
      formData.append(`addons[${addon.id}]`, 'on')
    })

    this.customFields.forEach((field) => {
      formData.append(field.id, field.value)
    })

    return formData
  }
}
export class HostingAcceptedRequestData extends AcceptedProductRequestData {
  constructor(
    cartIndex: string,
    billingCycle: billingCycles | undefined,
    addons: AddonDTO[],
    customFields: CustomField[],
    token: string
  ) {
    super(cartIndex, token)
    this.billingCycle = billingCycle
    this.addons = addons
    this.customFields = customFields
  }

  get formData(): FormData {
    const formData = new FormData()

    formData.append('ajax', this.ajax.toString())
    formData.append('a', this.a)
    formData.append('i', this.i)
    formData.append('configure', this.configure.toString())

    if (this.billingCycle) {
      formData.append('billingcycle', this.billingCycle)
    }

    this.addons.forEach((addon) => {
      formData.append(`addons[${addon.id}]`, 'on')
    })

    this.customFields.forEach((field) => {
      formData.append(field.id, field.value)
    })

    return formData
  }
}
