import { ServerModel } from '@/models'
import { ModalView } from '@/abstract'
import { TOKEN } from '@/utils'

export class ServerConfigModalView extends ModalView<ServerModel> {
  private submitButton: HTMLButtonElement
  private hostname: string | null = null
  private rootPassword: string | null = null
  private ns1: string | null = null
  private ns2: string | null = null
  private token: string

  constructor(modal: HTMLElement) {
    super(modal)

    const submitButton = this.modal.querySelector<HTMLButtonElement>(
      'button.js-add-server-details'
    )
    const hostnameInput = this.modal.querySelector<HTMLInputElement>(
      'input[name=hostname]'
    )
    const passwordInput =
      this.modal.querySelector<HTMLInputElement>('input[name=rootpw]')
    const ns1Input = this.modal.querySelector<HTMLInputElement>(
      'input[name=ns1prefix]'
    )
    const ns2Input = this.modal.querySelector<HTMLInputElement>(
      'input[name=ns2prefix]'
    )

    if (!submitButton) {
      throw new Error('ServerConfigModalView: submit button is undefined')
    }
    if (!hostnameInput) {
      throw new Error('ServerConfigModalView: hostname input is undefined')
    }
    if (!passwordInput) {
      throw new Error('ServerConfigModalView: password input is undefined')
    }
    if (!ns1Input) {
      throw new Error('ServerConfigModalView: ns1 input is undefined')
    }
    if (!ns2Input) {
      throw new Error('ServerConfigModalView: ns2 input is undefined')
    }

    if (!TOKEN) {
      throw new Error('ServerConfigModal: TOKEN is undefined')
    }
    this.token = TOKEN
    this.submitButton = submitButton

    hostnameInput.addEventListener('change', (event) => {
      event.preventDefault()
      const target = event.target as HTMLInputElement
      const value = target.value
      this.hostname = value
      this.checkSubmitStatus()
    })

    passwordInput.addEventListener('change', (event) => {
      event.preventDefault()
      const target = event.target as HTMLInputElement
      const value = target.value
      this.rootPassword = value
      this.checkSubmitStatus()
    })

    ns1Input.addEventListener('change', (event) => {
      event.preventDefault()
      const target = event.target as HTMLInputElement
      const value = target.value
      this.ns1 = value
      this.checkSubmitStatus()
    })

    ns2Input.addEventListener('change', (event) => {
      event.preventDefault()
      const target = event.target as HTMLInputElement
      const value = target.value
      this.ns2 = value
      this.checkSubmitStatus()
    })
  }

  onSubmit(model: ServerModel): Promise<ServerModel> {
    return new Promise((resolve) => {
      this.submitButton.addEventListener('click', (event) => {
        event.preventDefault()
        if (!this.areInputsNull) {
          const serverProduct = model.clone()
          serverProduct.setHostname(this.hostname as string)
          serverProduct.setRootPassword(this.rootPassword as string)
          serverProduct.setNs1Prefix(this.ns1 as string)
          serverProduct.setNs2Prefix(this.ns2 as string)
          this.close()

          resolve(serverProduct)
        }
      })
    })
  }

  private get areInputsNull(): boolean {
    return this.hostname && this.rootPassword && this.ns1 && this.ns2
      ? false
      : true
  }

  private checkSubmitStatus(): void {
    if (!this.areInputsNull) {
      this.submitButton.removeAttribute('disabled')
    } else {
      this.submitButton.setAttribute('disabled', '')
    }
  }
}
