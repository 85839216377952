import { CartResponseBody } from '../../core/theme'
import { API_ENDPOINT } from '../../utils/variables'

const HTTP_CART = `${API_ENDPOINT}/cart`

const getNumberOfItemsInCart = (cart: CartResponseBody): number => {
  let number = 0

  number = number + (cart?.domains?.length ?? 0)
  number = number + (cart?.products?.length ?? 0)

  return number
}

const getCartContents = async (): Promise<CartResponseBody> => {
  const responseRAW = await fetch(HTTP_CART, {
    method: 'GET',
    mode: 'cors',
    credentials: 'include'
  })

  const response: CartResponseBody = await responseRAW.json()
  return response
}

export const setupCart = async (
  buttons: NodeListOf<HTMLButtonElement>
): Promise<void> => {
  const cart = await getCartContents()

  buttons.forEach((button) => {
    const itemsInCartEL = button.querySelector('.js-number')
    if (!itemsInCartEL) {
      throw new Error('Cart: number holder is missing')
    }
    itemsInCartEL.textContent = getNumberOfItemsInCart(cart).toString()
  })
}
