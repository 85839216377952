import Fuse from 'fuse.js'

class Language {
  public country: string
  public language: string
  public code: string
  public href: string

  constructor(country: string, language: string, code: string, href: string) {
    this.country = country
    this.language = language
    this.code = code
    this.href = href
  }
}

class LanguageUtils {
  private fuse: Fuse<Language>

  constructor(fuse: Fuse<Language>) {
    this.fuse = fuse
  }

  static convertToObject(element: Element): Language {
    const country = element.querySelector('.country')?.textContent
    const language =
      element.querySelector('.language')?.textContent || 'No Language'
    const codeEl = element.querySelector('.fflag')
    const codeClass = codeEl?.classList.item(2)
    const code = codeClass?.slice(-2)
    const href = element.getAttribute('href')

    if (country && language && code && href) {
      return new Language(country, language, code, href)
    }

    throw new Error(
      "The Language Component Isn't set properly: the required data is missing"
    )
  }

  static convertToElement(language: Language): Element {
    const anchorEl = document.createElement('a')
    anchorEl.setAttribute('role', 'button')
    anchorEl.classList.add(
      'language-selector__language',
      'js-language-selector__language'
    )
    anchorEl.setAttribute('href', language.href)

    const flagEl = document.createElement('div')
    flagEl.classList.add('flag')
    const flagIcon = document.createElement('span')
    flagIcon.classList.add(
      'fflag ff-md',
      `fflag-${language.code.toUpperCase()}`
    )
    flagEl.append(flagIcon)

    const textEl = document.createElement('div')
    textEl.classList.add('text')
    const countryEl = document.createElement('span')
    countryEl.classList.add('country')
    countryEl.textContent = language.country
    const languageEl = document.createElement('span')
    languageEl.classList.add('language')
    languageEl.textContent = language.language
    textEl.append(countryEl)
    textEl.append(languageEl)

    anchorEl.append(flagEl)
    anchorEl.append(textEl)

    return anchorEl
  }

  search(term: string): Language[] {
    // skipcq
    return this.fuse.search(term).map((result) => result.item)
  }
}

const attachToRoot = (root: Element, languages: Language[]): void => {
  const elements = languages.map((lang) => LanguageUtils.convertToElement(lang))
  root.innerHTML = ''
  elements.forEach((el) => root.append(el))
}

export const setupLanguageSelector = (element: Element): void => {
  const searchLanguageInput = element.querySelector('.js-action__search input')
  const languageListRoot = element.querySelector(
    '.js-language-selector__list-of-languages'
  )
  const languageNodeList = element.querySelectorAll(
    '.js-language-selector__language'
  )
  const availableLanguages = Array.from(languageNodeList).map((element) =>
    LanguageUtils.convertToObject(element)
  )

  // skipcq
  const fuse: Fuse<Language> = new Fuse(availableLanguages, {
    keys: ['country']
  })

  const langUtils = new LanguageUtils(fuse)

  searchLanguageInput?.addEventListener('input', (event) => {
    event.preventDefault()
    const target = event.target as HTMLInputElement
    const term: string = target?.value

    if (!languageListRoot) {
      return
    }

    if (!term) {
      attachToRoot(languageListRoot, availableLanguages)
      return
    }

    const results = langUtils.search(term)

    attachToRoot(languageListRoot, results)
  })
}
