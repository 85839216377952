export const API_ENDPOINT: string =
  process.env.WHMCS_API ?? 'http://whmcs.test/custom/api.php'
export const WHMCS_ROOT: string =
  process.env.WHMCS_ROOT ?? 'http://whmcs.test/cloud/'

const tokenInput = document.querySelector<HTMLInputElement>(
  "input[type=hidden][name='token']"
)
export const TOKEN = tokenInput?.value

const appendRoot = (url: string) => {
  return `${WHMCS_ROOT}${url}`
}

export const URL_CONF_PRODUCT = appendRoot('cart.php?a=confproduct')
export const URL_ADD_PRODUCT = appendRoot('cart.php?a=add')
export const URL_ADD_PRODUCT_WITH_DOMAIN = appendRoot(
  'cart.php?a=add&domainselect=1'
)
export const URL_UPDATE_DOMAIN_PERIOD = appendRoot(
  'cart.php?a=updateDomainPeriod'
)
export const URL_CONF_DOMAIN = appendRoot('cart.php?a=confdomains')
export const URL_CHECK_DOMAIN = appendRoot('index.php?rp=/domain/check')
