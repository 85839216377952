export const setupBuildTagging = (
  rootElement: HTMLElement,
  context: string
): void => {
  const buildDateAsString: string | undefined = process.env.__buildDate__
  if (buildDateAsString) {
    const dateObj = new Date(buildDateAsString)

    const options: Intl.DateTimeFormatOptions = {
      minute: 'numeric',
      hour: 'numeric',
      second: 'numeric',
      day: 'numeric',
      month: '2-digit',
      year: 'numeric'
    }

    const formattedDate = new Intl.DateTimeFormat('en-GB', options).format(
      dateObj
    )
    const buildDateEl = document.createElement('input')
    buildDateEl.type = 'hidden'
    buildDateEl.name = 'build-date'
    buildDateEl.value = context + ': ' + formattedDate

    rootElement.append(buildDateEl)
  }
}
