const getCountDownDate = (countdown: HTMLElement): Date => {
  let { endDate } = countdown.dataset

  if (!endDate) {
    endDate = `Jan 5, ${new Date().getFullYear() + 1} 15:37:25`
  }

  const _date = new Date(endDate)
  const date = Date.UTC(
    _date.getUTCFullYear(),
    _date.getMonth(),
    _date.getDate(),
    _date.getHours(),
    _date.getMinutes(),
    _date.getSeconds(),
    _date.getMilliseconds()
  )

  return new Date(date)
}

export const setupCountdown = (countdown: HTMLElement): void => {
  const dayEl = countdown.querySelector('.js-days')
  const hourEl = countdown.querySelector('.js-hours')
  const minuteEl = countdown.querySelector('.js-minutes')
  const secondEl = countdown.querySelector('.js-seconds')

  if (!dayEl) {
    throw new Error("Countdown: 'day' element isn't set")
  }

  if (!hourEl) {
    throw new Error("Countdown: 'hour' element isn't set")
  }

  if (!minuteEl) {
    throw new Error("Countdown: 'minute' element isn't set")
  }

  if (!secondEl) {
    throw new Error("Countdown: 'second' element isn't set")
  }

  // REF: https://www.w3schools.com/howto/howto_js_countdown.asp

  // Set the date we're counting down to
  const countDownDate = getCountDownDate(countdown)

  const timer = setInterval((): void => {
    // Get today's date and time
    const now = new Date()

    // Find the distance between now and the count down date
    const distance = countDownDate.getTime() - now.getTime()

    // Time calculations for days, hours, minutes and seconds
    const days = Math.floor(distance / (1000 * 60 * 60 * 24))
    const hours = Math.floor(
      (distance % (1000 * 60 * 60 * 24)) / (1000 * 60 * 60)
    )
    const minutes = Math.floor((distance % (1000 * 60 * 60)) / (1000 * 60))
    const seconds = Math.floor((distance % (1000 * 60)) / 1000)

    // Display the result in the element with id="demo"
    dayEl.textContent = days.toString()
    hourEl.textContent = hours.toString()
    minuteEl.textContent = minutes.toString()
    secondEl.textContent = seconds.toString()

    // If the count down is finished, write some text
    if (distance < 0) {
      clearInterval(timer)
      dayEl.textContent = '--'
      hourEl.textContent = '--'
      minuteEl.textContent = '--'
      secondEl.textContent = '--'
    }
  }, 1000)
}
