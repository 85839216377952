export const API_ENDPOINT: string =
  process.env.WHMCS_API ?? '/cloud/custom/api.php'
export const WHMCS_ROOT: string | undefined = process.env.WHMCS_ROOT

if (!WHMCS_ROOT) {
  throw new Error('WHMCS_ROOT is undefined')
}

const appendRoot = (url: string) => {
  return `${WHMCS_ROOT}${url}`
}

export const URL_CONF_PRODUCT = appendRoot('/cart.php?a=confproduct')
export const URL_ADD_PRODUCT = appendRoot('/cart.php?a=add')
export const URL_ADD_PRODUCT_WITH_DOMAIN = appendRoot(
  '/cart.php?a=add&domainselect=1'
)
export const URL_UPDATE_DOMAIN_PERIOD = appendRoot(
  '/cart.php?a=updateDomainPeriod'
)
export const URL_CONF_DOMAIN = appendRoot('/cart.php?a=confdomains')
