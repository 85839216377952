import { HostingModel, ServerModel } from '@/models'
import { RegXUtils } from '@/utils'

export class CustomFieldView {
  private submitButton?: HTMLButtonElement
  private inputs: HTMLInputElement[] = []
  private currentProductEl?: HTMLElement
  private customFields: { id: string; value: string }[] = []
  private regX: RegXUtils
  private widget: HTMLElement

  constructor(widget: HTMLElement, regX: RegXUtils) {
    this.widget = widget
    this.regX = regX
  }

  private get isValid(): boolean {
    return this.inputs.every((input) => {
      const { type, value, checked } = input
      if (type !== 'checkbox') {
        return this.regX.isNotEmpty(value)
      }
      return checked
    })
  }

  private checkIfCanSubmit() {
    if (this.isValid) {
      this.submitButton?.removeAttribute('disabled')
    } else {
      if (this.submitButton && !this.submitButton.getAttribute('disabled')) {
        this.submitButton.setAttribute('disabled', '')
      }
    }
  }

  public setCurrentProductEl(pid: string): void {
    const customFieldElement = this.widget.querySelector<HTMLElement>(
      `.pid-${pid}.configure-custom-fields`
    )
    const submitButton = this.widget.querySelector<HTMLButtonElement>(
      `.pid-${pid} button.js-add-custom-fields`
    )

    if (!submitButton) {
      throw new Error('CustomFieldModalView: submit button is undefined')
    }

    if (!customFieldElement) {
      throw new Error(
        `CustomFieldsView: customField section for product ${pid} not set`
      )
    }

    this.submitButton = submitButton
    this.currentProductEl = customFieldElement
    this.setupProductEl()
  }

  private setupProductEl() {
    this.detachCurrentProductEl()
    this.currentProductEl?.classList.remove('hidden')
    const inputs = (
      this.currentProductEl as HTMLElement
    ).querySelectorAll<HTMLInputElement>(
      'input[name^=customfield], select[name^=customfield]'
    )
    this.inputs = Array.from(inputs)
    this.inputs.forEach((input) => {
      input.addEventListener('change', this.onInputChange.bind(this))
      this.checkAndExtractValue(input)
    })
    this.checkIfCanSubmit()
  }

  private detachCurrentProductEl() {
    this.currentProductEl?.classList.add('hidden')
    this.inputs.forEach((input) =>
      input.removeEventListener('change', this.onInputChange.bind(this))
    )
  }

  private onInputChange = (event: Event) => {
    event.preventDefault()
    const target = event.target as HTMLInputElement
    this.checkAndExtractValue(target)
    this.checkIfCanSubmit()
  }

  private checkAndExtractValue(target: HTMLInputElement) {
    const { name: id, value, type, checked } = target
    if (type === 'checkbox') {
      if (checked) {
        this.addCustomField(id, 'on')
      } else {
        this.removeCustomField(id)
      }
    } else {
      if (this.regX.isNotEmpty(value)) {
        this.addCustomField(id, value)
      } else {
        this.removeCustomField(id)
      }
    }
  }

  private addCustomField(id: string, value: string) {
    this.customFields.push({
      id,
      value
    })
  }

  private removeCustomField(id: string) {
    this.customFields = this.customFields.filter((field) => field.id !== id)
  }

  public onSubmit(
    model: ServerModel | HostingModel
  ): Promise<ServerModel | HostingModel> {
    if (!this.submitButton || this.inputs.length < 1) {
      throw new Error('CustomFieldModalView: set product element first')
    }

    return new Promise((resolve) => {
      this.submitButton?.addEventListener('click', (event) => {
        event.preventDefault()
        if (this.isValid) {
          const newModel = model.clone()
          this.customFields.forEach((field) => {
            newModel.addCustomField(field.id, field.value)
          })
          resolve(newModel)
        }
      })
    })
  }
}
