import { ProductDTO } from '@/abstract'
import { productTypes, productPaymentTypes } from '@/core'
import { billingCycles } from '@/core/ryanada'
import { AddonDTO } from './addon.dto'

export class ServerDTO extends ProductDTO {
  public hostname: string
  public rootPassword: string
  public ns1prefix: string
  public ns2prefix: string

  constructor(
    id: string,
    productType: productTypes,
    paymentType: productPaymentTypes,
    requiresDomain: boolean,
    billingCycle: billingCycles,
    addons: AddonDTO[],
    hasCustomFields: boolean,
    hostname: string,
    rootPassword: string,
    ns1prefix: string,
    ns2prefix: string
  ) {
    super(
      id,
      productType,
      paymentType,
      requiresDomain,
      billingCycle,
      addons,
      hasCustomFields
    )
    this.hostname = hostname
    this.rootPassword = rootPassword
    this.ns1prefix = ns1prefix
    this.ns2prefix = ns2prefix
  }
}
