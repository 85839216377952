import { uiUtils } from '../utils'
import { setupGroupNav, deactivateAllNavGroups } from './navigation/group-menu'
import { setupMobileGroupNav } from './navigation/group-menu-mobile'
import { setupLanguageSelector } from './navigation/language-selector'
import { setupCart } from './navigation/cart'
import { setupAuth } from './navigation/auth'
import { WPMenuDesktopView, WPMenuMobileView } from 'ts/view/wp-menu.view'

export const setupNavigation = (): void => {
  const navigation = document.querySelector<HTMLElement>('#navigation')

  const navbarMenu = navigation?.querySelector<HTMLElement>('.js-navbar-menu')
  const navGroups = navigation?.querySelectorAll<HTMLAnchorElement>(
    '.navbar__desktop .js-nav-group'
  )

  document.body.addEventListener('click', () => {
    if (navGroups) {
      deactivateAllNavGroups(navGroups)
    }
  })

  const cartButtons = navigation?.querySelectorAll<HTMLButtonElement>(
    '.navbar__desktop .js-navbar-cart, .navbar__mobile .js-navbar-cart'
  )
  const loginLogoutButtons = document.querySelectorAll<HTMLAnchorElement>(
    '.navbar__desktop .js-auth-login-logout, .navbar__mobile--collapsible .js-auth-login-logout'
  )
  const registerClientAreaButtons =
    document.querySelectorAll<HTMLAnchorElement>(
      '.navbar__desktop .js-auth-register-client-area, .navbar__mobile--collapsible .js-auth-register-client-area'
    )

  const languageSelectorButton = navigation?.querySelector(
    '.js-toggle-language-selector'
  )
  const languageSelector = document.querySelector<HTMLElement>(
    '.js-language-selector'
  )
  const closeLanguageSelectorButton =
    languageSelector?.querySelector('.js-action__close')
  const mobileNavbar = document.querySelector<HTMLElement>('.js-navbar-mobile')
  const mobileNavbarGroupList =
    mobileNavbar?.querySelectorAll<HTMLAnchorElement>('.js-nav__group')

  const wpMenuDesktopEl = navigation?.querySelector<HTMLElement>('.js-wp-menu')
  const wpMenuMobileEl = mobileNavbar?.querySelector<HTMLElement>('.js-wp-menu')

  if (wpMenuDesktopEl) {
    new WPMenuDesktopView(wpMenuDesktopEl)
  }

  if (wpMenuMobileEl) {
    new WPMenuMobileView(wpMenuMobileEl)
  }

  navbarMenu?.addEventListener('click', (event) => {
    event.preventDefault()
    if (navbarMenu) {
      uiUtils.toggleWidget(navbarMenu)
    }
    if (mobileNavbar) {
      uiUtils.toggleWidget(mobileNavbar)
    }
    if (languageSelector) {
      uiUtils.deactivateWidget(languageSelector)
    }
  })

  navGroups?.forEach((navGroup) => {
    if (navigation) {
      setupGroupNav(navGroup, navigation)
    }
  })

  mobileNavbarGroupList?.forEach((navGroup) => {
    setupMobileGroupNav(navGroup)
  })

  languageSelectorButton?.addEventListener('click', (event) => {
    event.preventDefault()
    if (navGroups) {
      deactivateAllNavGroups(navGroups)
    }
    if (mobileNavbar) {
      uiUtils.deactivateWidget(mobileNavbar)
    }
    if (navbarMenu) {
      uiUtils.deactivateWidget(navbarMenu)
    }
    if (languageSelector) {
      uiUtils.toggleWidget(languageSelector)
    }
  })

  closeLanguageSelectorButton?.addEventListener('click', (event) => {
    event.preventDefault()
    if (mobileNavbar) {
      uiUtils.deactivateWidget(mobileNavbar)
    }
    if (navbarMenu) {
      uiUtils.deactivateWidget(navbarMenu)
    }
    if (languageSelector) {
      uiUtils.toggleWidget(languageSelector)
    }
  })

  if (languageSelector) {
    setupLanguageSelector(languageSelector)
  }

  if (cartButtons) {
    void setupCart(cartButtons)
  }

  if (loginLogoutButtons) {
    void setupAuth(loginLogoutButtons, registerClientAreaButtons)
  }
}
