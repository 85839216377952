import { AddonDTO } from '@/dtos'
import { billingCycles, RequestDataProps } from '@/core/ryanada'
import { CustomField } from '@/abstract'

export abstract class ProductRequestData implements RequestDataProps {
  protected ajax = 1
  protected a = 'confproduct'
  protected configure = true
  protected billingCycle?: billingCycles = undefined
  protected addons: AddonDTO[] = []
  protected customFields: CustomField[] = []
  protected token: string

  constructor(token: string) {
    this.token = token
  }

  abstract formData: FormData
}

export abstract class AcceptedProductRequestData extends ProductRequestData {
  public i: string
  constructor(i: string, token: string) {
    super(token)
    this.i = i
  }
}
