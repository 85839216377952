export class WPMenuDesktopView {
  constructor(menuEl: HTMLElement) {
    const listItemsWithSubMenus = menuEl.querySelectorAll<HTMLLIElement>(
      'li.menu-item-has-children'
    )
    listItemsWithSubMenus.forEach(this.setupSubMenu)
  }

  private setupSubMenu = (parent: HTMLLIElement): void => {
    const subMenu = parent.querySelector<HTMLUListElement>('.sub-menu')

    if (!subMenu) {
      throw new Error(
        'WPMenuDesktopView: Sub Menu is null in nav item marked as having sub item'
      )
    }

    parent.addEventListener('mouseover', () => {
      subMenu.classList.add('show')
    })

    parent.addEventListener('mouseleave', () => {
      subMenu.classList.remove('show')
    })
  }
}

export class WPMenuMobileView {
  constructor(menuEl: HTMLElement) {
    const listItemsWithSubMenus = menuEl.querySelectorAll<HTMLLIElement>(
      'li.menu-item-has-children'
    )

    listItemsWithSubMenus.forEach(this.setupSubMenu)
  }

  private setupSubMenu = (parent: HTMLLIElement): void => {
    const subMenu = parent.querySelector<HTMLUListElement>('.sub-menu')

    if (!subMenu) {
      throw new Error(
        'WPMenuDesktopView: Sub Menu is null in nav item marked as having sub item'
      )
    }

    parent.addEventListener('click', (e) => {
      e.preventDefault()
      e.stopPropagation()
      subMenu.classList.toggle('show')
    })
  }
}
