import { ProductViewModel } from '@/view-models'
import { ProductHTTP } from '@/http'
import { RegXUtils, TOKEN, URL_ADD_PRODUCT } from '@/utils'
import { Modals } from '@/core'
import {
  DomainSelectionModalView,
  ServerConfigModalView,
  CartModalManager,
  CustomFieldView,
  CustomFieldModalView
} from '@/views'

export const handleCTAButton = async (
  productTab: HTMLElement
): Promise<void> => {
  const productHTTP = new ProductHTTP(new RegXUtils())
  const productViewModel = new ProductViewModel(productHTTP)
  if (!TOKEN) {
    throw new Error("Product Tab Home: Token isn't set")
  }
  let product = productViewModel.makeModel(productTab, TOKEN)

  const serverConfigModal =
    document.querySelector<HTMLElement>('.js-server-config')

  const domainSelectionModal = document.querySelector<HTMLElement>(
    '.js-domain-selection'
  )
  const customFieldModal = document.querySelector<HTMLElement>(
    '.modal.js-custom-fields'
  )
  const customFieldEl = document.querySelector<HTMLElement>(
    '#custom-fields-view'
  )

  if (!customFieldModal) {
    throw new Error('Product Offer: custom field modal el is missing')
  }

  if (!customFieldEl) {
    throw new Error('Product Offer: custom field wrapper el is missing')
  }

  if (!serverConfigModal) {
    throw new Error('Product Tab Home: server config modal el is missing')
  }

  if (!domainSelectionModal) {
    throw new Error('Product Tab Home: domain selection modal el is missing')
  }

  const customFieldView = new CustomFieldView(customFieldEl, new RegXUtils())
  const customFieldModalView = new CustomFieldModalView(
    customFieldModal,
    customFieldView
  )
  const serverConfigModalView = new ServerConfigModalView(serverConfigModal)
  const domainSelectionModalView = new DomainSelectionModalView(
    domainSelectionModal,
    new RegXUtils()
  )

  const modalManager = new CartModalManager(
    domainSelectionModalView,
    serverConfigModalView,
    customFieldModalView
  )

  // Fix: Redirect To WHMCS Addd Domain when product requires domain
  if (product.requiresDomain || product.isServer || product.hasCustomFields) {
    location.replace(`${URL_ADD_PRODUCT}&pid=${product.id}`)
    return
  }

  if (product.requiresDomain) {
    modalManager.open(Modals.DOMAIN)
    product = await modalManager.onSubmit(Modals.DOMAIN, product)
  }

  if (product.isServer) {
    modalManager.open(Modals.SERVER)
    product = await modalManager.onSubmit(Modals.SERVER, product)
  }

  if (product.hasCustomFields) {
    modalManager.open(Modals.CUSTOM_FIELDS)
    product = await modalManager.onSubmit(Modals.CUSTOM_FIELDS, product)
  }

  void productViewModel.addToCart(product)
}
