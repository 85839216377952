export abstract class ModalView<K> {
  protected backdrop: HTMLElement
  protected body: HTMLElement
  protected modal: HTMLElement

  constructor(modal: HTMLElement) {
    this.modal = modal
    const body = document.querySelector('body')
    if (!body) {
      throw new Error('Modal: Body is missing')
    }
    this.body = body
    this.backdrop = document.createElement('div')
    this.backdrop.classList.add('modal-backdrop', 'fade', 'in')
    this.modal.style.overflowY = 'auto'

    modal.querySelectorAll('.js-action__close')?.forEach((action) => {
      action.addEventListener('click', (event) => {
        event.preventDefault()
        this.close()
      })
    })

    this.backdrop.addEventListener('click', (event) => {
      event.preventDefault()
      this.close()
    })
  }

  open(): void {
    this.modal.style.display = 'block'
    this.modal.classList.add('in')
    this.body?.append(this.backdrop)
    this.body.style.overflow = 'hidden'
  }

  close(): void {
    this.modal.style.display = 'none'
    this.modal.classList.remove('in')
    this.backdrop.remove()
    this.body.style.overflow = 'auto'
  }

  abstract onSubmit(model: K): Promise<K>
}
