import { productPaymentTypes, productTypes } from '@/core'
import { billingCycles } from '@/core/ryanada'
import { AddonDTO, HostingDTO, ServerDTO } from '@/dtos'
import { ProductHTTP } from '@/http'
import { HostingModel, ServerModel } from '@/models'

export class ProductViewModel {
  private httpProvider: ProductHTTP
  constructor(httpProvider: ProductHTTP) {
    this.httpProvider = httpProvider
  }

  setHTTPProvider(provider: ProductHTTP): void {
    this.httpProvider = provider
  }

  makeModel(tabEl: HTMLElement, token: string): ServerModel | HostingModel {
    const pidInput = tabEl.querySelector<HTMLInputElement>('input[name=pid]')
    const billingCycleInput = tabEl.querySelector<HTMLInputElement>(
      'input[name=billingcycle]'
    )
    const domainRequiredInput = tabEl.querySelector<HTMLInputElement>(
      'input[name=requires-domain]'
    )
    const productTypeInput = tabEl.querySelector<HTMLInputElement>(
      'input[name=product-type]'
    )
    const paymentTypeInput = tabEl.querySelector<HTMLInputElement>(
      'input[name=payment-type]'
    )
    const requiresDomainInput = tabEl.querySelector<HTMLInputElement>(
      'input[name=requires-domain]'
    )
    const domainInput =
      tabEl.querySelector<HTMLInputElement>('input[name=domain]')
    const addonInputs = tabEl.querySelectorAll<HTMLInputElement>(
      'input[name^=addons].js-current-value'
    )
    const cartIndexInput = tabEl.querySelector<HTMLInputElement>(
      'input[name=i].js-current-value'
    )
    const hasCustomFieldsInput = tabEl.querySelector<HTMLInputElement>(
      'input[name=has-custom-fields]'
    )

    const pid = pidInput?.value
    const billingCycle = billingCycleInput?.value as billingCycles | undefined
    const domainRequired = domainRequiredInput?.value
    const productType = productTypeInput?.value as productTypes | undefined
    const paymentType = paymentTypeInput?.value as
      | productPaymentTypes
      | undefined
    const requiresDomain = requiresDomainInput?.value
    const domain = domainInput?.value
    const cartIndex = cartIndexInput?.value
    const hasCustomFields = hasCustomFieldsInput?.value
    const addons: AddonDTO[] = []

    if (!pid) {
      throw new Error('Product ViewModel: pid not set')
    }

    if (!billingCycle) {
      throw new Error('Product ViewModel: billing Cycle not set')
    }

    if (!domainRequired) {
      throw new Error('Product ViewModel: domain-required not set')
    }

    if (!productType) {
      throw new Error('Product ViewModel: product type is not set')
    }

    if (!paymentType) {
      throw new Error('Product ViewModel: payment type is not set')
    }

    if (!requiresDomain) {
      throw new Error('Product ViewModel: requires domain is not set')
    }

    if (!hasCustomFields) {
      throw new Error('Product ViewModel: has-custom-fields is not set')
    }

    addonInputs.forEach((input) => {
      const { id, name } = input.dataset as { name?: string; id?: string }

      if (!id) {
        throw new Error('ProductViewModel: addon id undefined')
      }

      if (!name) {
        throw new Error('ProductViewModel: addon name undefined')
      }

      const addonDto = new AddonDTO(id, name)
      addons.push(addonDto)
    })

    if (productType === productTypes.SERVER) {
      const dto = new ServerDTO(
        pid,
        productType,
        paymentType,
        !!Number.parseInt(requiresDomain),
        billingCycle,
        addons,
        !!Number.parseInt(hasCustomFields),
        '',
        '',
        '',
        ''
      )

      const model = new ServerModel(dto, token)

      if (domain) {
        model.domain = domain
      }

      if (cartIndex) {
        model.setCartIndex(cartIndex)
      }

      return model
    }

    const dto = new HostingDTO(
      pid,
      productType,
      paymentType,
      !!Number.parseInt(requiresDomain),
      billingCycle,
      addons,
      !!Number.parseInt(hasCustomFields)
    )

    const model = new HostingModel(dto, token)

    if (domain) {
      model.domain = domain
    }

    if (cartIndex) {
      model.setCartIndex(cartIndex)
    }

    return model
  }

  async addToCart(product: HostingModel | ServerModel): Promise<boolean> {
    return this.httpProvider.post(product)
  }
}
