import { uiUtils } from '../../utils'

export const deactivateAllNavGroups = (
  navGroups: NodeListOf<HTMLElement>
): void => {
  navGroups.forEach((navGroup) => {
    const groupDropdownMenu = navGroup.querySelector<HTMLElement>(
      '.group__dropdown-menu'
    )
    if (groupDropdownMenu) {
      uiUtils.deactivateWidget(groupDropdownMenu)
    }
  })
}

export const setupGroupNav = (
  groupNav: HTMLAnchorElement,
  navigation: HTMLElement
): void => {
  const groupName = groupNav.querySelector('.group__name')
  const groupDropdownMenu = groupNav.querySelector<HTMLElement>(
    '.group__dropdown-menu'
  )
  const languageSelector = document.querySelector<HTMLElement>(
    '.js-language-selector'
  )

  groupName?.addEventListener('click', (event) => {
    event.preventDefault()
    const { navIndex } = groupNav.dataset
    if (!navIndex) {
      throw new Error('Navigation: tabIndex mot set')
    }
    const navGroups = navigation.querySelectorAll<HTMLElement>(
      `.navbar__desktop .js-nav-group:not([data-nav-index='${navIndex}'])`
    )
    deactivateAllNavGroups(navGroups)
    if (languageSelector) {
      uiUtils.deactivateWidget(languageSelector)
    }
    if (groupDropdownMenu) {
      uiUtils.toggleWidget(groupDropdownMenu)
    }
    event.stopPropagation()
  })
}
