import { HostingModel, ServerModel } from '@/models'
import { ModalView } from '@/abstract'
import { RegXUtils } from '@/utils'

export class DomainSelectionModalView extends ModalView<
  HostingModel | ServerModel
> {
  private domainInputs: NodeListOf<HTMLInputElement>
  private submitButton: HTMLButtonElement | null
  private domain: string | null = null
  private regX: RegXUtils

  constructor(modal: HTMLElement, regX: RegXUtils) {
    super(modal)
    this.regX = regX
    this.domainInputs = this.modal.querySelectorAll<HTMLInputElement>(
      'input[name=domain][type=radio]'
    )
    const selectedDomainInput = this.modal.querySelector<HTMLInputElement>(
      'input[name=domain][type=radio]:checked'
    )
    const manualDomainInput = this.modal.querySelector<HTMLInputElement>(
      'input[name=manual-domain][type=text]'
    )

    const selectedDomain = selectedDomainInput?.value

    if (selectedDomain && this.regX.isDomain(selectedDomain)) {
      this.domain = selectedDomain
    }

    if (!manualDomainInput) {
      throw new Error('DomainSelectionModalView: Manual Domain Input is null')
    }

    this.submitButton = this.modal.querySelector<HTMLButtonElement>(
      'button.js-add-product-domain'
    )
    this.domainInputs?.forEach((input) => {
      input.addEventListener('change', this.handleInput.bind(this))
    })

    manualDomainInput.addEventListener('change', this.handleInput.bind(this))
  }

  private handleInput = (event: Event) => {
    event.preventDefault()
    const target = event.target as HTMLInputElement
    const value = target.value
    const status = this.regX.isDomain(value)
    if (status) {
      this.submitButton?.removeAttribute('disabled')
      this.domain = value
      return
    }

    if (this.submitButton && !this.submitButton.getAttribute('disabled')) {
      this.submitButton.setAttribute('disabled', '')
    }
  }

  onSubmit(
    productModel: HostingModel | ServerModel
  ): Promise<HostingModel | ServerModel> {
    return new Promise((resolve) => {
      this.submitButton?.addEventListener('click', (event) => {
        event.preventDefault()
        if (this.domain) {
          const copy = productModel.clone()
          copy.domain = this.domain
          this.close()
          resolve(copy)
        }
      })
    })
  }
}
