import {
  viewLessFeatures,
  viewMoreFeatures
} from './products/toggle-more-features'
import { openProductPlans } from './products/toggle-plan'
import { handleCTAButton } from './products/handleCTAButton'

const handleGroup = (button: HTMLElement, widget: HTMLElement): void => {
  if (button.classList.contains('active')) {
    return
  }

  const { tabIndex } = button.dataset

  if (!tabIndex) {
    throw new Error('Products Table: tabIdex Not set')
  }

  const buttonsToDeactivate = widget.querySelectorAll(
    `.js-pricing__selector:not([data-tab-index='${tabIndex}'])`
  )

  buttonsToDeactivate.forEach((_button) => _button.classList.remove('active'))

  button.classList.add('active')

  const tabToDeactivate = widget.querySelectorAll<HTMLElement>(
    `.js-product-pricing:not([data-tab-index='${tabIndex}'])`
  )
  const tabToActivate = widget.querySelector<HTMLElement>(
    `.js-product-pricing[data-tab-index='${tabIndex}']`
  )

  if (tabToDeactivate && tabToActivate) {
    openProductPlans(tabToActivate, tabToDeactivate)
  }
}

export const setupProductTable = (): void => {
  const productGroupWidget = document.querySelector<HTMLElement>(
    '.js-products-groups'
  )

  const groupSelectorsAnchor =
    productGroupWidget?.querySelectorAll<HTMLElement>('a.js-pricing__selector')

  const groupSelectorsSelect =
    productGroupWidget?.querySelectorAll<HTMLSelectElement>(
      'select.js-pricing__selector'
    )

  const productTabsInGroup =
    productGroupWidget?.querySelectorAll<HTMLElement>('.js-pricing__tab')

  groupSelectorsAnchor?.forEach((selector) => {
    selector.addEventListener('click', (event) => {
      event.preventDefault()
      if (productGroupWidget) {
        handleGroup(selector, productGroupWidget)
      }
    })
  })

  groupSelectorsSelect?.forEach((selector) => {
    selector.addEventListener('change', (event) => {
      event.preventDefault()
      selector.dataset.tabIndex = selector.value
      selector.classList.remove('active')
      if (productGroupWidget) {
        handleGroup(selector, productGroupWidget)
      }
    })
  })

  productTabsInGroup?.forEach((productTab) => {
    const featuresContainer =
      productTab.querySelector<HTMLElement>('.js-tab__features')
    const viewMoreFeaturesBtn = productTab.querySelector('.js-features__toggle')
    const ctaButton = productTab.querySelector('.js-cta__button')

    ctaButton?.addEventListener('click', (event) => {
      event.preventDefault()
      void handleCTAButton(productTab)
    })

    viewMoreFeaturesBtn?.addEventListener('click', (event) => {
      event.preventDefault()
      viewMoreFeaturesBtn.classList.toggle('active')
      if (featuresContainer?.classList.contains('active')) {
        viewLessFeatures(featuresContainer)
        viewMoreFeaturesBtn.textContent = 'See More Features'
        return
      }
      if (featuresContainer) {
        viewMoreFeatures(featuresContainer)
      }
      viewMoreFeaturesBtn.textContent = 'See Less Features'
    })

    viewMoreFeaturesBtn?.classList.add('active')
    if (featuresContainer) {
      viewLessFeatures(featuresContainer)
    }
  })

  const productSelect = productGroupWidget?.querySelector<HTMLSelectElement>(
    'select.js-pricing__selector'
  )
  const firstOption = productSelect?.querySelector<HTMLOptionElement>('option')
  const productAnchor = productGroupWidget?.querySelector(
    'a.js-pricing__selector'
  )

  if (productSelect?.dataset && firstOption?.value) {
    productSelect.dataset.tabIndex = firstOption.value
  }

  productAnchor?.classList.add('active')

  if (productSelect && productGroupWidget) {
    handleGroup(productSelect, productGroupWidget)
  }
}
