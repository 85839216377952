import { HostingModel, ServerModel } from '@/models'
import { Modals } from '@/core'
import {
  DomainSelectionModalView,
  ServerConfigModalView,
  CustomFieldModalView
} from '@/views'

export class CartModalManager {
  protected domainSelectionModalView: DomainSelectionModalView
  protected serverConfigModalView: ServerConfigModalView
  protected customFieldModalView: CustomFieldModalView
  constructor(
    domainSelectionModalView: DomainSelectionModalView,
    serverConfigModalView: ServerConfigModalView,
    customFieldModalView: CustomFieldModalView
  ) {
    this.domainSelectionModalView = domainSelectionModalView
    this.serverConfigModalView = serverConfigModalView
    this.customFieldModalView = customFieldModalView
  }

  open(modalName: Modals): void {
    this.closeAll()

    switch (modalName) {
      case Modals.DOMAIN:
        this.domainSelectionModalView.open()
        break
      case Modals.SERVER:
        this.serverConfigModalView.open()
        break
      case Modals.CUSTOM_FIELDS:
        this.customFieldModalView.open()
        break
      default:
        throw new Error('CartModalManager: undefined modal name')
    }
  }

  onSubmit(
    modalName: Modals,
    model: HostingModel | ServerModel
  ): Promise<HostingModel | ServerModel> {
    switch (modalName) {
      case Modals.DOMAIN:
        return this.domainSelectionModalView.onSubmit(model)
      case Modals.SERVER:
        return this.serverConfigModalView.onSubmit(model as ServerModel)
      case Modals.CUSTOM_FIELDS:
        return this.customFieldModalView.onSubmit(model)
      default:
        throw new Error('CartModalManager: undefined modal name')
    }
  }

  protected closeAll(): void {
    this.domainSelectionModalView.close()
    this.serverConfigModalView.close()
    this.customFieldModalView.close()
  }
}
