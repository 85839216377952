import { ProductModel } from '@/abstract'
import { AddonDTO, ServerDTO } from '@/dtos'
import { productTypes } from '@/core'
import { ModelMethods } from '@/core/ryanada'
import {
  ServerAcceptedRequestData,
  ServerRequestData
} from '../http/request-data/server.request-data'

export class ServerModel
  extends ProductModel<ServerDTO>
  implements
    ModelMethods<ServerRequestData, ServerAcceptedRequestData, ServerModel>
{
  public clone(): ServerModel {
    const dtoCopy = JSON.parse(JSON.stringify(this.dto)) as ServerDTO
    const modelCopy = new ServerModel(dtoCopy, this.token)
    modelCopy.domain = this.domain
    modelCopy.customFields = this.customFields
    return modelCopy
  }

  public getRequestData(): ServerRequestData {
    return new ServerRequestData(
      this.dto.billingCycle,
      this.dto.addons,
      this.customFields,
      this.token,
      this.dto.hostname,
      this.dto.rootPassword,
      this.dto.ns1prefix,
      this.dto.ns2prefix
    )
  }
  public getAcceptedRequestData(i: string): ServerAcceptedRequestData {
    return new ServerAcceptedRequestData(
      i,
      this.dto.billingCycle,
      this.dto.addons,
      this.customFields,
      this.token,
      this.dto.hostname,
      this.dto.rootPassword,
      this.dto.ns1prefix,
      this.dto.ns2prefix
    )
  }

  public get isServer(): boolean {
    return this.dto.productType === productTypes.SERVER
  }

  public get requestData(): FormData {
    return new FormData()
  }

  public setHostname(hostname: string): void {
    this.dto.hostname = hostname
  }

  public setRootPassword(rootPassword: string): void {
    this.dto.rootPassword = rootPassword
  }

  public setNs1Prefix(prefix: string): void {
    this.dto.ns1prefix = prefix
  }

  public setNs2Prefix(prefix: string): void {
    this.dto.ns2prefix = prefix
  }

  public addAddon(addon: AddonDTO): void {
    this.dto.addons.push(addon)
  }

  public removeAddon(id: string): void {
    this.dto.addons = this.dto.addons.filter((addon) => addon.id !== id)
  }
}
