import { billingCycles } from '@/core/ryanada'
import { AddonDTO } from '@/dtos'
import {
  AcceptedProductRequestData,
  ProductRequestData,
  CustomField
} from '@/abstract'

export class ServerRequestData extends ProductRequestData {
  protected hostname: string
  protected rootPassword: string
  protected ns1prefix: string
  protected ns2prefix: string

  constructor(
    billingCycle: billingCycles | undefined,
    addons: AddonDTO[],
    customFields: CustomField[],
    token: string,
    hostname: string,
    rootPassword: string,
    ns1prefix: string,
    ns2prefix: string
  ) {
    super(token)
    this.billingCycle = billingCycle
    this.addons = addons
    this.customFields = customFields
    this.hostname = hostname
    this.rootPassword = rootPassword
    this.ns1prefix = ns1prefix
    this.ns2prefix = ns2prefix
  }

  get formData(): FormData {
    const formData = new FormData()

    formData.append('ajax', this.ajax.toString())
    formData.append('a', this.a)
    formData.append('token', this.token)
    formData.append('configure', this.configure.toString())

    if (this.billingCycle) {
      formData.append('billingcycle', this.billingCycle)
    }

    this.addons.forEach((addon) => {
      formData.append(`addons[${addon.id}]`, 'on')
    })

    this.customFields.forEach((field) => {
      formData.append(field.id, field.value)
    })

    formData.append('hostname', this.hostname)
    formData.append('rootpw', this.rootPassword)
    formData.append('ns1prefix', this.ns1prefix)
    formData.append('ns2prefix', this.ns2prefix)

    return formData
  }
}
export class ServerAcceptedRequestData extends AcceptedProductRequestData {
  protected hostname: string
  protected rootPassword: string
  protected ns1prefix: string
  protected ns2prefix: string

  constructor(
    i: string,
    billingCycle: billingCycles | undefined,
    addons: AddonDTO[],
    customFields: CustomField[],
    token: string,
    hostname: string,
    rootPassword: string,
    ns1prefix: string,
    ns2prefix: string
  ) {
    super(i, token)
    this.billingCycle = billingCycle
    this.addons = addons
    this.customFields = customFields
    this.hostname = hostname
    this.rootPassword = rootPassword
    this.ns1prefix = ns1prefix
    this.ns2prefix = ns2prefix
  }

  get formData(): FormData {
    const formData = new FormData()

    formData.append('ajax', this.ajax.toString())
    formData.append('a', this.a)
    formData.append('i', this.i)
    formData.append('configure', this.configure.toString())

    if (this.billingCycle) {
      formData.append('billingcycle', this.billingCycle)
    }

    this.addons.forEach((addon) => {
      formData.append(`addons[${addon.id}]`, 'on')
    })

    this.customFields.forEach((field) => {
      formData.append(field.id, field.value)
    })

    formData.append('hostname', this.hostname)
    formData.append('rootpw', this.rootPassword)
    formData.append('ns1prefix', this.ns1prefix)
    formData.append('ns2prefix', this.ns2prefix)

    return formData
  }
}
