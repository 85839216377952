import { RegXUtils } from '@/utils'
import { CommonHTTP } from '@/abstract'
import { URL_ADD_PRODUCT, URL_CHECK_DOMAIN } from '@/utils'
import { HostingModel, ServerModel } from '@/models'
import { DomainCheckResponseData } from '@/core/ryanada'

export class ProductHTTP extends CommonHTTP {
  private regX: RegXUtils
  constructor(regX: RegXUtils) {
    super()
    this.regX = regX
  }

  private handleDomainNotRequired(
    product: HostingModel | ServerModel,
    url: string
  ): number {
    const cartIndexParam = this.regX.extractCartIndexParam(url)

    if (!cartIndexParam) {
      location.reload()
      return -1
    }

    const cartIndex = this.regX.extractCartIndex(cartIndexParam)
    return Number.parseInt(cartIndex)
  }

  private async getConfigurationURL(
    product: HostingModel | ServerModel
  ): Promise<string | undefined> {
    const pid = product.id

    const response = await fetch(`${URL_ADD_PRODUCT}&pid=${pid}`)

    if (!this.regX.hasConfigureProduct(response.url)) {
      return
    }

    return response.url
  }

  private async checkDomain(
    product: HostingModel | ServerModel
  ): Promise<number> {
    const requestData = product.getRequestData()
    const token = requestData.formData.get('token')
    const pid = product.id
    const domain = product.domain

    if (!token) {
      throw new Error('ProductHTTP: token is undefined')
    }

    if (!pid) {
      throw new Error('ProductHTTP: pid is undefined')
    }

    if (!domain) {
      throw new Error('ProductHTTP: domain is undefined')
    }
    const formData = new FormData()
    formData.append('token', token.toString())
    formData.append('pid', pid)
    formData.append('domain', domain)
    formData.append('sld', domain)
    formData.append('type', 'incart')
    formData.append('tld', '')
    formData.append('source', 'cartAddDomain')

    const response = await fetch(URL_CHECK_DOMAIN, {
      method: 'POST',
      body: formData
    })

    const responseData: DomainCheckResponseData = await response.json()

    if (responseData.result.length < 1) {
      return -1
    }

    return responseData.result[0].num
  }

  private async addToCart(
    product: HostingModel | ServerModel,
    cartIndex: string
  ): Promise<void> {
    const requestData = product.getAcceptedRequestData(cartIndex)

    const response = await fetch('cart.php', {
      method: 'POST',
      body: requestData.formData
    })

    this.handleReloadPage(response)
  }

  async post(product: HostingModel | ServerModel): Promise<boolean> {
    if (product.isInCart) {
      await this.addToCart(product, product.cartIndex as string)
      return true
    }

    if (product.requiresDomain && !product.domain) {
      throw new Error('ProductHTTP: domain is required but not provided')
    }

    if (product.requiresDomain) {
      const cartIndex = await this.checkDomain(product)
      void this.addToCart(product, cartIndex.toString())
      return true
    } else {
      const url = await this.getConfigurationURL(product)
      if (url) {
        const cartIndex = this.handleDomainNotRequired(product, url)
        void this.addToCart(product, cartIndex.toString())
        return true
      }
      const dummyResponse = new Response(undefined, {
        status: 200
      })
      this.handleReloadPage(dummyResponse)
      return false
    }
  }
}
